<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-8">Ekranlar</div>
            <div class="col-4 text-right">
              <CButton color="success" square
                       size="sm"
                       @click="openModalEvent('create', 'pageForm', null, 'Yeni Sayfa Ekle')"
              >
                Sayfa Ekle
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="pages"
            :fields="fields"
            column-filter
            :items-per-page="10"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update', 'pageForm', lastPage, 'Sayfayı Düzenle')"
            v-on:refresh="getAllPages"
            :loading="loading"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('update', 'pageForm', item, 'Sayfayı Düzenle')">
                    Düzenle
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('destroy', 'confirm', item, 'Sayfayı Sil', item.title+' sayfasını silmek üzeresiniz. Bunu yapmak istediğinizden emin misiniz?')">
                    Sil
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crudPage"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crudPage"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />
  </CRow>
</template>
<script>
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'

export default {
  name: 'Pages',
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'admin',
      form: 'pageForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      lastPage: {},
      fields: [
        {key: 'show_details', label: 'İşlemler' },
        {key: 'id'},
        {key: 'title', label: 'Sayfa Adı'},
        {key: 'uniqueID', label: 'Özel Sayfa Adı'},
        {key: 'description', label: 'Açıklama'},
        {key: 'displayOrder', label: 'Sıralama No'},
      ],
      activePage: 1,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    modalProps: function(val) {
      if(val && val.parentPage)
        this.modalProps.parentPageName = val.parentPage.id
    }
  },
  components:{
    ConfirmModal, FormModal
  },
  computed: {
    pages: function() {
      return this.$store.getters.allPage
    },
    loading: function() {
      return this.$store.getters.adminLoading
    }
  },
  methods: {
    rowClicked(item) {
      this.lastPage = item;
    },
    openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    async getAllPages() {
      this.$store.dispatch('allPage_list');
      this.$store.dispatch('allParentPage_list');

    },
    async crudPage(page, actionType) {
      let params = {
        id: page.id ? page.id : 0,
        actionType: actionType,
        title: page.title,
        uniqueID: page.uniqueID,
        activeFlag: page.activeFlag ? page.activeFlag : false,
        description: page.description,
        displayOrder: page.displayOrder,
        parentPageId: page.parentPageName,
        pageIcon: page.pageIcon

      };
      await this.$store.dispatch('page_action', params);
      if(this.$store.getters.adminStatus.success) {
       this.getAllPages();
       this.openModalEvent() 
      }
    }
  },
  created() {
    this.getAllPages();
  }
}
</script>
